/**!
 *  @name				   app.js (assets/js/app.js)
 *
 *  @client	       NORDPOL-900
 *  @description	 Main Application
 *  @copyright 	   (c) 2019 Ansgar Hiller <ansgar@weigelstein.de>
 */

require('../scss/app.scss');

import $ from 'jquery';
import 'jquery-ui';
import 'bootstrap';
global.$ = $;

import { gsap } from 'gsap';
global.gsap = gsap;

require('bowser/bundled');
import Bowser from 'bowser';
global.Bowser = Bowser;

const
DEBUG = GLOBAL_DEBUG,
VERBOSITY = GLOBAL_VERBOSITY || 0,
WS = {},
PAGERS = [],
DEVICE = Bowser.parse(window.navigator.userAgent);

global.DEBUG = DEBUG;
global.VERBOSITY = VERBOSITY;
global.WS = WS;
global.DEVICE = DEVICE;
global.ENV = ENV;
global.PAGERS = PAGERS;
global.MODAL_ID = MODAL_ID;
global.ORIGIN = ORIGIN;
global.REFERER = REFERER;
global.GET = GET;
global.LOCALE = LOCALE;
global.CSS_PATH = CSS_PATH;

import App from './components/app';
import Utils from './helper/utils';
global.UTILS = Utils;

if (DEBUG && VERBOSITY > 0)
{
    console.log(`
        ================================================ \n
        DEVICE DETECTION \n
        ================================================ \n
        Browser:  ${DEVICE.browser.name} (${DEVICE.browser.version}) \n
        OS:       ${DEVICE.os.name} (${DEVICE.os.version}) \n
        Engine:   ${DEVICE.engine.name} (${DEVICE.engine.version}) \n
        Platform: ${DEVICE.platform.type} (${DEVICE.platform.vendor}) \n
        ================================================ \n
    `);

    console.log(`
        ================================================ \n
        GLOBALS \n
        ================================================ \n
        DEBUG:      ${global.DEBUG} \n
        VERBOSITY:  ${global.VERBOSITY} \n
        ENV:        ${global.ENV} \n
        LOCALE:     ${global.LOCALE} \n
        ORIGIN:     ${global.ORIGIN} \n
        REFERER:    ${global.REFERER} \n
        MODAL_ID:   ${global.MODAL_ID} \n
        CSS_PATH:   ${global.CSS_PATH} \n
        ================================================ \n
    `);
}

$(document).ready(function()
{   'use strict';

    global.APP = new App($(document));

    /* ADD a listener to global.APP like this: */
    /*
    global.APP.on('refreshOnce', function(app)
        {
            console.log('REFRESH_ONCE');
            console.log(app.isSidebarExpanded);
        }
    );
    */
});

/* UTILS
----------------------------------------------------------------------------------------------------*/
function openURL(url, target) {
    target = target || '_self';
    window.open(url,target);
}
